import { gql } from "@apollo/client";

export const GET_CUSTOMER_CLAIMS = gql`
  query {
    customerclaimList {
      image
      order_id
      item_id
      entity_id
      claimid
      date
      product_name
      qty
      price
      totalprice
      claim_status
      issue_type
      refund_type
      description
      product_image_url
      communication {
        sender
        text
        timestamp
        is_read_by_customer
        is_read_by_seller
      }
    }
  }
`;

export const VIEW_CUSTOMER_CLAIM = gql`
  query viewCustomerClaim($entity_id: Int!) {
    viewCustomerClaim(entity_id: $entity_id) {
      entity_id
      claimid
      customer_id
      customer_email
      order_id
      item_id
      date
      product_name
      qty
      price
      totalprice
      claim_status
      issue_type
      description
      image
      product_image_url
      seller_refund_type
    }
  }
`;

export const GET_CHAT_COMMUNICATION = gql`
  query getChatCommunication($claimid: Int!) {
    getChatCommunication(claimid: $claimid) {
      claimid
      communication {
        sender
        text
        timestamp
        is_read_by_customer
        is_read_by_seller
      }
    }
  }
`;
