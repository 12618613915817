import React, { useContext, useEffect } from "react";
import "./ClaimsProductForm.scss";
import {
  Box,
  Button,
  MenuItem,
  Paper,
  Select,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextareaAutosize,
  Typography,
} from "@mui/material";
import { useForm, Controller } from "react-hook-form";
import Dropzone, { DropzoneState } from "react-dropzone";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useMutation, useQuery } from "@apollo/client";
import * as queries from "../../../../../operations/queries";
import { CountryContext } from "../../../MainComponent";
import * as mutations from "../../../../../operations/mutations";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
// import CloseIcon from "@mui/icons-material/Close";

interface ClaimsFormInputs {
  issueType?: string;
  problemDescription?: string;
  termsAccepted?: boolean;
}

const ClaimsProductForm = () => {
  const [files, setFiles] = React.useState<File[]>([]);
  const [searchParams] = useSearchParams();
  const [errorMessage, setErrorMessage] = React.useState<string>("");
  const [base64Files, setBase64Files] = React.useState<
    { base64: string; name: string }[]
  >([]);
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { store } = useContext(CountryContext);
  const { orderNumber } = useParams();
  const loggedInToken = localStorage.getItem("loggedInCust");
  const props = searchParams.get("props");
  const editClaimsId = searchParams?.get("claim_id")
    ? searchParams?.get("claim_id")
    : "";
  const urlParams = new URLSearchParams(window.location.search);
  const idFromUrl = urlParams.get("id");

  const issueType = [
    t("buyer_dashboard.claim_form.claim_reason_1"),
    t("buyer_dashboard.claim_form.claim_reason_2"),
    t("buyer_dashboard.claim_form.claim_reason_3"),
    t("buyer_dashboard.claim_form.claim_reason_4"),
    t("buyer_dashboard.claim_form.claim_reason_5"),
    t("buyer_dashboard.claim_form.claim_reason_6"),
  ];

  // const {store}=useContext(CountryContext)
  const {
    control,
    handleSubmit,
    register,
    reset,
    setValue,
    formState: { errors },
  } = useForm<ClaimsFormInputs>({
    defaultValues: {
      issueType: "",
      problemDescription: "",
      termsAccepted: false,
    },
  });

  const { data: claimsDataList } = useQuery(
    queries.claimsQueries.GET_CUSTOMER_CLAIMS,
    {
      context: {
        headers: {
          Authorization: loggedInToken
            ? `Bearer ${JSON.parse(loggedInToken)}`
            : "",
          Connection: "keep-alive",
          // store: store,
        },
      },
    }
  );

  const claimsData = claimsDataList?.customerclaimList || [];

  const [addClaim] = useMutation(mutations.addClaimsMutation.ADD_CLAIM, {
    context: {
      headers: {
        Authorization: loggedInToken
          ? `Bearer ${JSON.parse(loggedInToken)}`
          : "",
        Connection: "keep-alive",
      },
    },
  });

  const [updateClaim] = useMutation(mutations.addClaimsMutation.UPDATE_CLAIM, {
    context: {
      headers: {
        Authorization: loggedInToken
          ? `Bearer ${JSON.parse(loggedInToken)}`
          : "",
        Connection: "keep-alive",
      },
    },
  });

  useEffect(() => {
    if (props === "edit" && claimsData) {
      const matchedClaim = claimsData?.find(
        (claim: any) => claim.entity_id?.toString() === editClaimsId?.toString()
      );

      if (matchedClaim) {
        setValue("issueType", matchedClaim.issue_type || "Damaged Product");
        setValue("problemDescription", matchedClaim.description || "");
      
        if (matchedClaim.image && Array.isArray(matchedClaim.image)) {
          const imageUrls = matchedClaim.image;

          const filePromises = imageUrls.map((imageUrl: any) => {
            const imageName =
              imageUrl?.toString().split("/").pop() ?? "default.jpg";

            return fetch(imageUrl)
              .then((response) => response.blob())
              .then((blob) => {
                const file = new File([blob], imageName, { type: blob.type });

                // Convert the file to base64
                return new Promise((resolve) => {
                  const reader = new FileReader();
                  reader.readAsDataURL(file);
                  reader.onload = () => {
                    resolve({
                      file: file,
                      base64: reader.result as string,
                      name: file.name,
                    });
                  };
                });
              })
              .catch((error) => {
                console.error("Error fetching image:", error);
                return null; 
              });
          });

          Promise.all(filePromises)
            .then((results) => {
              const validFiles = results.filter((item) => item !== null); 
              const files = validFiles.map((item) => item?.file);
              const base64Files = validFiles.map((item) => ({
                base64: item?.base64,
                name: item?.name,
              }));
              setFiles(files);
              setBase64Files(base64Files);
            })
            .catch((error) => console.error("Error processing images:", error));
        }
      }
    }
    //eslint-disable-next-line
  }, [props, claimsDataList, editClaimsId, setValue]);

  const {
    data: orderDetailsRes,
    loading: orderDeatailsLoading,
    refetch: refetchOrderDetails,
  } = useQuery(queries.customerQueries.GET_ORDER_DETAILS, {
    variables: {
      order_number: orderNumber,
      item_id: idFromUrl,
    },
    context: {
      headers: {
        Authorization: loggedInToken
          ? `Bearer ${JSON.parse(loggedInToken)}`
          : "",
        Connection: "keep-alive",
      },
    },
  });

  useEffect(() => {
    refetchOrderDetails();
  }, [refetchOrderDetails, store]);

  const orderDetails = orderDetailsRes?.customerOrdersDetail?.[0] || [];

  const onSubmit = async (data: ClaimsFormInputs) => {
    if (files.length === 0) {
      setErrorMessage(t("buyer_dashboard.claim_form.file_err"));
      return;
    }

    if (!data.termsAccepted) {
      setErrorMessage(t("buyer_dashboard.claim_form.term_err"));
      return;
    }

    setErrorMessage("");

    const formattedFiles = base64Files.map((file) => ({
      base64_encoded_file: file.base64,
      name: file.name,
    }));

    if (props === "add") {
      try {

        const formData = {
          order_id: orderNumber,
          issue_type: data.issueType,
          description: data.problemDescription,
          file: formattedFiles,
        };

        await addClaim({
          variables: formData,
        });

        reset();
        setFiles([]);
        const basePath = window.location.pathname.split("/claims")[0];
        navigate(`${basePath}/claims`);
      } catch (error) {
        console.error("Error submitting claim:", error);
        toast.error(t("buyer_dashboard.claim_form.form_cathc_err"));
      }
    } else if (props === "edit") {
      try {
        const formData = {
          entity_id: editClaimsId,
          issue_type: data.issueType,
          description: data.problemDescription,
          file: formattedFiles,
        };

        await updateClaim({
          variables: formData,
        });
        const currentPath = window.location.pathname;
        const basePath = currentPath.split("/claims")[0];
        navigate(`${basePath}/claims`);
        reset();
      } catch (error) {
        console.error("Error adding card:", error);
        toast.error(t("buyer_dashboard.claim_form.form_cathc_err"));
      }
    }
  };

  const handleDrop = (acceptedFiles: File[]) => {
    if (files.length + acceptedFiles.length > 6) {
      setErrorMessage(t("buyer_dashboard.claim_form.file_length_err"));
      return;
    }

    setErrorMessage("");

    const promises = acceptedFiles.map((file) => {
      return new Promise<{ base64: string; name: string }>(
        (resolve, reject) => {
          const reader = new FileReader();
          reader.readAsDataURL(file);
          reader.onload = () =>
            resolve({ base64: reader.result as string, name: file.name });
          reader.onerror = (error) => reject(error);
        }
      );
    });

    Promise.all(promises)
      .then((base64FilesWithNames) => {
        setBase64Files((prevBase64Files) => [
          ...prevBase64Files,
          ...base64FilesWithNames,
        ]);
        setFiles((prevFiles) => [...prevFiles, ...acceptedFiles]);
      })
      .catch((error) => {
        console.error("Error converting file to Base64:", error);
      });
  };

  // const closePage = () => {
  //   const currentPath = window.location.pathname;
  //   const basePath = currentPath.split("/claims")[0];  
  //   const newPath = `/claims${basePath};`
  //   navigate(`${newPath}`); 
  // };

  return (
    <>
      <Box className="claim-form-page directionrtl">
        <Box className="heading-sec">
          <Box>
            <h4>{t("buyer_dashboard.claim_form.title")}</h4>

            <p>{t("buyer_dashboard.claim_form.desc")}</p>
          </Box>
          {/* <div className="close-btn">
            <button onClick={() => closePage()}>
              <CloseIcon />
            </button>
          </div> */}
        </Box>

        <Box className="claim-details">
          <Box className="claim-item-summary">
            <TableContainer component={Paper}>
              <Table aria-label="order details table">
                <TableHead>
                  <TableRow>
                    <TableCell align="center">
                      <p>{t("buyer_dashboard.OrderSummary.Product")}</p>
                    </TableCell>
                    <TableCell align="center">
                      <p>{t("buyer_dashboard.OrderSummary.order_id")} </p>
                    </TableCell>
                    <TableCell align="center">
                      <p>{t("buyer_dashboard.OrderSummary.SKU")}</p>
                    </TableCell>
                    <TableCell align="center">
                      <p>{t("buyer_dashboard.OrderSummary.Quantity")}</p>
                    </TableCell>
                    <TableCell align="center">
                      <p>{t("buyer_dashboard.OrderSummary.Price")}</p>
                    </TableCell>
                    <TableCell align="center">
                      <p>{t("buyer_dashboard.OrderSummary.status")}</p>
                    </TableCell>
                  </TableRow>
                </TableHead>
                {!orderDeatailsLoading ? (
                  <TableBody>
                    <TableRow key={orderDetails?.product_sku}>
                      <TableCell align="center">
                        <Box>
                          <img
                            src={orderDetails?.product_image}
                            alt="Product"
                            style={{ maxWidth: "70px" }}
                          />
                        </Box>
                      </TableCell>
                      <TableCell align="center">
                        <p>{orderNumber}</p>
                      </TableCell>
                      <TableCell align="center">
                        <p>{orderDetails?.product_name}</p>
                      </TableCell>

                      <TableCell align="center">
                        <p>{orderDetails?.qty}</p>
                      </TableCell>

                      <TableCell align="center">
                        <p>
                          {orderDetails?.currency} {orderDetails?.price}
                        </p>
                      </TableCell>
                      <TableCell align="center">
                        <p>{orderDetails?.status}</p>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                ) : (
                  <TableBody>
                    {[1].map((row) => (
                      <TableRow key={row}>
                        <TableCell align="center">
                          <p>
                            <Skeleton
                              variant="rectangular"
                              width={70}
                              height={70}
                            />
                          </p>
                        </TableCell>
                        <TableCell align="center">
                          <p>
                            <Skeleton
                              variant="rectangular"
                              width={80}
                              height={20}
                            />
                          </p>
                        </TableCell>
                        <TableCell align="center">
                          <p>
                            <Skeleton
                              variant="rectangular"
                              width={80}
                              height={20}
                            />
                          </p>
                        </TableCell>
                        <TableCell align="center">
                          <p>
                            <Skeleton
                              variant="rectangular"
                              width={80}
                              height={20}
                            />
                          </p>
                        </TableCell>
                        <TableCell align="center">
                          <p>
                            <Skeleton
                              variant="rectangular"
                              width={80}
                              height={20}
                            />
                          </p>
                        </TableCell>
                        <TableCell align="center">
                          <p>
                            <Skeleton
                              variant="rectangular"
                              width={80}
                              height={20}
                            />
                          </p>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                )}
              </Table>
            </TableContainer>
          </Box>
        </Box>

        <Box className="content-sec">
          <Box className="claim-form-heading">
            <h3>{t("buyer_dashboard.claim_form.small_desc")}</h3>
          </Box>

          <form onSubmit={handleSubmit(onSubmit)}>
            <Box className="form-fields-main-box">
              {/* Notification Number */}
              <Box className="form-field-box">
                <p className="form-field-title">
                  {t("buyer_dashboard.claim_form.notification_num")}
                </p>
                <Controller
                  name="issueType"
                  control={control}
                  rules={{
                    required: t(
                      "buyer_dashboard.claim_form.notification_num_err"
                    ),
                  }}
                  render={({ field }) => (
                    <Select
                      {...field}
                      displayEmpty
                      inputProps={{ "aria-label": "Without label" }}
                    >
                      <MenuItem value="" disabled sx={{ fontSize: "13px" }}>
                        {t("buyer_dashboard.claim_form.select")}
                      </MenuItem>
                      {issueType?.map((option) => (
                        <MenuItem
                          key={option}
                          value={option}
                          sx={{ fontSize: "13px" }}
                        >
                          {option}
                        </MenuItem>
                      ))}
                    </Select>
                  )}
                />
                {errors.issueType && (
                  <p className="error">{errors.issueType.message}</p>
                )}
              </Box>
            </Box>

            {/* Problem Description */}
            <Box className="form-fields-main-box">
              <Box className="form-field-box">
                <p className="form-field-title">
                  {t("buyer_dashboard.claim_form.problem_desc")}
                </p>
                <TextareaAutosize
                  minRows={3}
                  // name="issueDescription"
                  placeholder={t(
                    "buyer_dashboard.claim_form.problem_desc_placeholder"
                  )}
                  {...register("problemDescription", {
                    required: t("buyer_dashboard.claim_form.problem_desc_err"),
                    maxLength: {
                      value: 255,
                      message: t("buyer_dashboard.claim_form.max_symbol_err"),
                    },
                  })}
                />
                {errors.problemDescription && (
                  <p className="error">{errors.problemDescription.message}</p>
                )}
              </Box>
            </Box>

            {/* File Upload */}
            <Box className="form-fields-main-box">
              <Box className="form-field-box">
                <p className="form-field-title">
                  {t("buyer_dashboard.claim_form.upload_file")}
                </p>
                <Dropzone
                  onDrop={handleDrop}
                  accept={{ "image/*": [], "application/pdf": [] }}
                >
                  {({
                    getRootProps,
                    getInputProps,
                    isDragActive,
                  }: DropzoneState) => (
                    <Box
                      className={`file-upload ${
                        isDragActive ? "drag-active" : ""
                      }`}
                      {...getRootProps()}
                    >
                      <input {...getInputProps()} />
                      {isDragActive ? (
                        <span>
                          {t("buyer_dashboard.claim_form.drag_file_msg")}
                        </span>
                      ) : (
                        <span>
                          {t("buyer_dashboard.claim_form.select_file_msg")}
                        </span>
                      )}
                    </Box>
                  )}
                </Dropzone>
                {errorMessage && (
                  <p className="error" style={{ color: "red" }}>
                    {errorMessage}
                  </p>
                )}
                <Box>
                  {base64Files?.map((file, index) => (
                    <Box
                      key={index}
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        marginBottom: 1,
                        mt: 1,
                        padding: 1,
                        border: "1px solid #ccc",
                        borderRadius: 1,
                        flexWrap: "wrap",
                        gap: 1,
                      }}
                    >
                      <Box>
                        <Typography variant="body2">{file.name}</Typography>
                      </Box>

                      <Box sx={{ display: "flex", flexWrap: "wrap", gap: 1 }}>
                        {/* View File Button */}
                        <Button
                          variant="outlined"
                          color="primary"
                          size="small"
                          sx={{ height: "30px", textTransform: "none" }}
                          onClick={() => {
                            const fileUrl = file.base64; 
                            const newWindow = window.open();
                            if (newWindow) {
                              newWindow.document.write(`
                                            <html>
                                              <head>
                                                <title>Uploaded File</title>
                                                <style>
                                                  body, html {
                                                    margin: 0;
                                                    padding: 0;
                                                    height: 100%;
                                                    display: flex;
                                                    flex-direction: column;
                                                    justify-content: center;
                                                    align-items: center;
                                                    background-color: #f0f0f0;
                                                  }
                                                  img {
                                                    max-width: 100%;
                                                    max-height: 90%;
                                                    object-fit: contain;
                                                  }
                                                  button {
                                                    margin-top: 10px;
                                                    padding: 5px 10px;
                                                    font-size: 16px;
                                                    cursor: pointer;
                                                    margin-top:20px;
                                                  }
                                                </style>
                                              </head>
                                              <body>
                                                <img src="${fileUrl}" alt="Uploaded File" />
                                                <button onclick="window.close()">Close</button>
                                              </body>
                                            </html>
                              `);
                              newWindow.document.close();
                            } else {
                              console.error(
                                "Popup blocked or new window could not be opened."
                              );
                            }
                          }}
                        >
                          View
                        </Button>

                        {/* Download File Button */}
                        <Button
                          variant="outlined"
                          color="primary"
                          size="small"
                          sx={{ height: "30px", textTransform: "none" }}
                          onClick={() => {
                            const link = document.createElement("a");
                            link.href = file.base64;
                            link.download = file.name; // Use the file name for download
                            link.click();
                          }}
                        >
                          Download
                        </Button>

                        {/* Remove File Button */}
                        <Button
                          variant="outlined"
                          color="error"
                          size="small"
                          sx={{ height: "30px", textTransform: "none" }}
                          onClick={() => {
                            setBase64Files((prevBase64Files) =>
                              prevBase64Files.filter((_, i) => i !== index)
                            );
                            setFiles((prevFiles) =>
                              prevFiles.filter((_, i) => i !== index)
                            );
                          }}
                        >
                          Remove
                        </Button>
                      </Box>
                    </Box>
                  ))}
                </Box>
              </Box>
            </Box>

            {/* Terms and Conditions Checkbox */}
            <Box className="form-fields-main-box">
              <Box className="form-field-box-checkbox">
                <Box>
                  <input
                    type="checkbox"
                    id="termsAccepted"
                    {...register("termsAccepted", {
                      required: "You must accept the terms and conditions",
                    })}
                    style={{ marginRight: "8px" }}
                    // checked={props === "edit" ? true : false}
                  />
                  <label htmlFor="termsAccepted">
                    I agree to the{" "}
                    <Link
                      to={`/${store}/cms/terms-of-use`}
                      target="_blank"
                      rel="noopener noreferrer"
                      style={{ color: "blue", textDecoration: "underline" }}
                    >
                      Terms and Conditions
                    </Link>
                  </label>
                  {errors.termsAccepted && (
                    <p className="error">{errors.termsAccepted.message}</p>
                  )}
                </Box>
              </Box>
            </Box>

            {/* Submit Button */}
            <Box className="submit-btn">
              <button
                className="fill-primary-btn"
                type="submit"
                style={{ cursor: "pointer" }}
              >
                {t("buyer_dashboard.claim_form.submit_btn")}
              </button>
            </Box>
          </form>
        </Box>
      </Box>
    </>
  );
};

export default ClaimsProductForm;
