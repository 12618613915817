import React, { useState } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Box,
  Typography,
  Button,
} from "@mui/material";
import { Close } from "@mui/icons-material";
import { useMutation } from "@apollo/client";
import * as mutations from "../../../../../../operations/mutations";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";

interface CancelOrderModalProps {
  isOpen: boolean;
  onClose: () => void;
  onConfirm: () => void;
  claimsData: any;
}

const ConfirmationModal: React.FC<CancelOrderModalProps> = ({
  isOpen,
  onClose,
  onConfirm,
  claimsData,
}) => {
  const [loadingCancel, setLoadingCancel] = useState(false);
  const [loadingAccept, setLoadingAccept] = useState(false);
  const loggedInToken = localStorage.getItem("loggedInCust");
  const { t } = useTranslation();

  const [customerResolution] = useMutation(
    mutations.addClaimsMutation.CUSTOMER_RESOLUTION_MUTATION,
    {
      context: {
        headers: {
          Authorization: loggedInToken
            ? `Bearer ${JSON.parse(loggedInToken)}`
            : "",
          Connection: "keep-alive",
        },
      },
    }
  );

  const handleConfirm = async (type: any) => {
    try {
      if (type === "rejected") setLoadingCancel(true);
      if (type === "accepted") setLoadingAccept(true);
      const response = await customerResolution({
        variables: {
          entity_id: claimsData?.entity_id,
          resolution: type,
        },
      });

      if (response.data.customerResolution.success) {
        toast.success(response.data.customerResolution.message);
      } else {
        console.error(
          "Refund acceptance failed:",
          response.data.customerResolution.message
        );
        toast.error(t("buyer_dashboard.claim_view.resolution_err"));
      }
      onConfirm();
    } catch (err) {
      console.error("Error during mutation:", err);
    } finally {
      setLoadingCancel(false);
      setLoadingAccept(false);
      onClose();
    }
  };

  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      fullWidth
      maxWidth="xs"
      className="directionrtl"
    >
      <Box sx={{ borderRadius: "2px", p: 2 }}>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          px={2}
          pt={1}
        >
          <DialogTitle sx={{ p: 0, fontWeight: "bold", fontSize: "1.25rem" }}>
            {t("buyer_dashboard.claim_view.refund_title")}
          </DialogTitle>
          <IconButton onClick={onClose}>
            <Close />
          </IconButton>
        </Box>
        <hr style={{ margin: "10px -15px", borderColor: "#F0ECEC" }} />
        <DialogContent>
          <Box mb={2}>
            <Typography
              variant="body2"
              gutterBottom
              sx={{ mb: 1.5, fontWeight: "500", fontSize: "14px" }}
            >
              {t("buyer_dashboard.claim_view.quest_msg")}
            </Typography>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button
            variant="outlined"
            color="secondary"
            onClick={() => handleConfirm("rejected")}
            fullWidth
            sx={{
              margin: "0px 17px",
              padding: "8px",
              borderRadius: 0,
              borderColor: "#F0ECEC",
              color: "#F44336",
              fontSize: "14px",
              textTransform: "capitalize",
            }}
            disabled={loadingCancel}
          >
            {loadingCancel
              ? t("buyer_dashboard.claim_view.process_btn")
              : t("buyer_dashboard.claim_view.no_btn")}
          </Button>
          <Button
            variant="contained"
            color="error"
            onClick={() => handleConfirm("accepted")}
            fullWidth
            sx={{
              margin: "0px 17px",
              padding: "8px",
              borderRadius: 0,
              backgroundColor: "#00b4d7",
              "&:hover": {
                backgroundColor: "#00b4d7",
              },
              color: "#fff",
              fontSize: "14px",
              textTransform: "capitalize",
            }}
            disabled={loadingAccept}
          >
            {loadingAccept
              ? t("buyer_dashboard.claim_view.process_btn")
              : t("buyer_dashboard.claim_view.yes_btn")}
          </Button>
        </DialogActions>
      </Box>
    </Dialog>
  );
};

export default ConfirmationModal;
