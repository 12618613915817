import React from 'react';
import './NoClaims.scss';
import noClaimsImg from '../../../../../assets/images/no-claims.png';
import { Box } from '@mui/material';

const NoClaims = () => {
  return (
    <>
        <Box className="no-claims">
          {/* eslint-disable-next-line */}
            <img src={noClaimsImg} alt="Image" />

            <h4>No claims requested</h4>

            <p>You have not requested any previous warranty claims</p>

        </Box>
    </>
  )
}

export default NoClaims
