import React, { useContext, useEffect, useState } from "react";
import "./ClaimsProductList.scss";
import {
  Badge,
  Box,
  MenuItem,
  Pagination,
  Select,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { useQuery } from "@apollo/client";
import * as queries from "../../../../operations/queries";
// import * as mutations from "../../../../operations/mutations";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { CountryContext } from "../../MainComponent";
import NoClaims from "./EmptyClaims/NoClaims";
import Loader from "../loader/Loader";

const ClaimsProductList = () => {
  // const [isdeleteClaim, setIsDeleteClaim] = React.useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [claimsData, setClaimsData] = useState<any[]>();
  const loggedInToken = localStorage.getItem("loggedInCust");
  const { t } = useTranslation();
  const { store } = useContext(CountryContext);

  const array = [
    { value: 10, label: "10" },
    { value: 15, label: "15" },
    { value: 20, label: "20" },
    { value: 30, label: "30" },
  ];

  const {
    data: claimsDataList,
    refetch: refetchListData,
    loading: claimsLoading,
  } = useQuery(queries.claimsQueries.GET_CUSTOMER_CLAIMS, {
    fetchPolicy: "network-only",
    context: {
      headers: {
        Authorization: loggedInToken
          ? `Bearer ${JSON.parse(loggedInToken)}`
          : "",
        Connection: "keep-alive",
        // store: store,
      },
    },
  });

  const processClaims = (claims: any) => {
    return claims.map((claim: any) => {
      const communication = claim?.communication || [];

      let read_by_customer = 0;
      let unread_by_customer = 0;
      let read_by_seller = 0;
      let unread_by_seller = 0;

      communication?.forEach((msg: any) => {
        msg.is_read_by_customer ? read_by_customer++ : unread_by_customer++;
        msg.is_read_by_seller ? read_by_seller++ : unread_by_seller++;
      });

      return {
        ...claim,
        read_by_customer,
        unread_by_customer,
        read_by_seller,
        unread_by_seller,
      };
    });
  };

  useEffect(() => {
    if (claimsDataList?.customerclaimList) {
      const enrichedClaims = processClaims(claimsDataList.customerclaimList);
      setClaimsData(enrichedClaims);
    }
  }, [claimsDataList]);

  // const [deleteClaim] = useMutation(mutations.addClaimsMutation.DELETE_CLAIM, {
  //   context: {
  //     headers: {
  //       Authorization: loggedInToken
  //         ? `Bearer ${JSON.parse(loggedInToken)}`
  //         : "",
  //       Connection: "keep-alive",
  //       store: store,
  //     },
  //   },
  // });

  // const handleDelete = async (entityId: any) => {
  //   try {
  //     await deleteClaim({ variables: { entity_id: parseInt(entityId) } });
  //     setIsDeleteClaim(true);
  //     toast.success(t("buyer_dashboard.claims.claim_delete_success"));
  //   } catch (err) {
  //     setIsDeleteClaim(false);
  //     toast.error(t("buyer_dashboard.claims.claim_delete_fail"));
  //   }
  // };

  // const claimsData = claimsDataList?.customerclaimList || [];

  useEffect(() => {
    refetchListData();
  }, [refetchListData, claimsDataList?.customerclaimList, store]);

  const handlePageChange = (event: any, newPage: any) => {
    setCurrentPage(newPage);
  };

  const handlePageSizeChange = (event: any) => {
    setPageSize(event.target.value);
    setCurrentPage(1);
  };

  const totalItems: number = claimsData?.length ?? 0;
  const totalPages = Math.ceil(totalItems / pageSize);
  const paginatedItems = Array.isArray(claimsData)
    ? [...claimsData]
        ?.sort(
          (a: any, b: any) =>
            new Date(b.date).getTime() - new Date(a.date).getTime()
        )
        ?.slice((currentPage - 1) * pageSize, currentPage * pageSize)
    : [];

  return (
    <>
      <Box className="claim-products-list-page">
        <Box className="heading-sec">
          <Box>
            <h4>{t("buyer_dashboard.claims.title")}</h4>
            <p>{t("buyer_dashboard.claims.desc")}</p>
          </Box>
          <Box></Box>
        </Box>

        {claimsLoading ? (
          <Loader />
        ) : claimsData && claimsData?.length > 0 ? (
          <>
            <Box className="content-sec">
              {/* Desktop View */}
              <Box
                sx={{
                  overflowX: "auto",
                  "&::-webkit-scrollbar": {
                    height: "6px",
                  },
                  "&::-webkit-scrollbar-thumb": {
                    backgroundColor: "#888",
                    borderRadius: "10px",
                  },
                  "&::-webkit-scrollbar-thumb:hover": {
                    backgroundColor: "#555",
                  },
                  "&::-webkit-scrollbar-track": {
                    backgroundColor: "#f1f1f1",
                  },
                }}
              >
                <Table className="web">
                  <TableHead>
                    <TableRow>
                      <TableCell>
                        {t("buyer_dashboard.claims.product")}
                      </TableCell>
                      <TableCell>{t("buyer_dashboard.claims.claim")}</TableCell>
                      <TableCell>{t("buyer_dashboard.claims.order")}</TableCell>
                      <TableCell>{t("buyer_dashboard.claims.date")}</TableCell>
                      <TableCell>
                        {t("buyer_dashboard.claims.product_name")}
                      </TableCell>
                      {/* <TableCell>{t("buyer_dashboard.claims.qty")}</TableCell> */}
                      <TableCell>{t("buyer_dashboard.claims.price")}</TableCell>
                      <TableCell>
                        {t("buyer_dashboard.claims.status")}
                      </TableCell>
                      <TableCell align="center">
                        {t("buyer_dashboard.claims.action")}
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {paginatedItems?.map((claim: any, index: any) => (
                      <TableRow key={index}>
                        <TableCell>
                          <Box>
                            <img
                              src={`${claim?.product_image_url}`}
                              alt="Product"
                              style={{ maxWidth: "70px" }}
                            />
                          </Box>
                        </TableCell>
                        <TableCell>
                          <Link
                            to={`view-claim?claim_id=${claim?.entity_id}`}
                            style={{
                              color: "#2b57a9",
                            }}
                          >
                            {claim?.claimid}
                          </Link>
                        </TableCell>
                        <TableCell>{claim?.order_id}</TableCell>
                        <TableCell>
                          {new Date(claim?.date).toLocaleDateString("en-GB")}
                        </TableCell>
                        <TableCell>{claim?.product_name}</TableCell>
                        {/* <TableCell>{claim?.qty}</TableCell> */}
                        <TableCell>{claim?.totalprice}</TableCell>
                        <TableCell>
                          {claim?.claim_status === "pending"
                            ? t("buyer_dashboard.claim_view.pending")
                            : claim?.claim_status === "in_review"
                            ? t("buyer_dashboard.claim_view.in_review")
                            : t("buyer_dashboard.claim_view.close")}
                        </TableCell>
                        <TableCell>
                          <Box
                            sx={{
                              display: "flex",
                              gap: "5px",
                              flexWrap: "wrap",
                              justifyContent: "center",
                            }}
                          >
                            {claim?.claim_status === "in_review" ||
                            claim?.claim_status === "closed" ? (
                              <button
                                style={{ cursor: "not-allowed", opacity: 0.5 }}
                                disabled
                              >
                                {t("buyer_dashboard.claims.edit")}
                              </button>
                            ) : (
                              <Link
                                to={`${claim?.order_id}?id=${claim?.item_id}&claim_id=${claim?.entity_id}&props=edit`}
                              >
                                <button style={{ cursor: "pointer" }}>
                                  {t("buyer_dashboard.claims.edit")}
                                </button>
                              </Link>
                            )}
                            <Badge
                              badgeContent={claim?.unread_by_customer}
                              sx={{
                                "& .MuiBadge-badge": {
                                  backgroundColor: "#c55050",
                                  color: "#fff",
                                  fontSize: "12px",
                                  minWidth: "20px",
                                  height: "20px",
                                  borderRadius: "10px",
                                },
                              }}
                              invisible={!claim?.unread_by_customer}
                            >
                              <Link
                                to={`view-claim?claim_id=${claim?.entity_id}`}
                                style={{
                                  color: "#2b57a9",
                                }}
                              >
                                <button style={{ cursor: "pointer" }}>
                                  {t("buyer_dashboard.claims.view")}
                                </button>
                              </Link>
                            </Badge>
                          </Box>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </Box>

              {/* Mobile View */}
              <Table className="mobile">
                {paginatedItems?.map((claim: any, index: any) => (
                  <TableBody key={index}>
                    <TableRow>
                      <TableCell className="header">
                        {t("buyer_dashboard.claims.product")}
                      </TableCell>
                      <TableCell align="center">
                        <Box>
                          <img
                            src={`${claim?.product_image_url}`}
                            alt="Product"
                            style={{ maxWidth: "70px" }}
                          />
                        </Box>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell className="header">
                        {t("buyer_dashboard.claims.claim")}
                      </TableCell>
                      <TableCell align="center">
                        <Link
                          to={`view-claim?claim_id=${claim?.entity_id}`}
                          style={{
                            color: "#2b57a9",
                          }}
                        >
                          {claim?.claimid}
                        </Link>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell className="header">
                        {t("buyer_dashboard.claims.order")}
                      </TableCell>
                      <TableCell align="center">{claim?.order_id}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell className="header">
                        {t("buyer_dashboard.claims.date")}
                      </TableCell>
                      <TableCell align="center">
                        {new Date(claim?.date).toLocaleDateString("en-GB")}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell className="header">
                        {t("buyer_dashboard.claims.product_name")}
                      </TableCell>
                      <TableCell align="center">{claim.product_name}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell className="header">
                        {t("buyer_dashboard.claims.price")}
                      </TableCell>
                      <TableCell align="center">{claim.totalprice}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell className="header">
                        {t("buyer_dashboard.claims.status")}
                      </TableCell>
                      <TableCell align="center">
                        {claim?.claim_status === "pending"
                          ? "Pending"
                          : claim?.claim_status === "in_review"
                          ? "In review"
                          : "Close"}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell className="header">
                        {t("buyer_dashboard.claims.action")}
                      </TableCell>
                      <TableCell>
                        <Box
                          sx={{
                            display: "flex",
                            gap: "5px",
                            flexWrap: "wrap",
                            justifyContent: "center",
                          }}
                        >
                          {claim?.claim_status === "in_review" ||
                          claim?.claim_status === "closed" ? (
                            <button
                              style={{ cursor: "not-allowed", opacity: 0.5 }}
                              disabled
                            >
                              {t("buyer_dashboard.claims.edit")}
                            </button>
                          ) : (
                            <Link
                              to={`${claim?.order_id}?id=${claim?.item_id}&claim_id=${claim?.entity_id}&props=edit`}
                            >
                              <button style={{ cursor: "pointer" }}>
                                {t("buyer_dashboard.claims.edit")}
                              </button>
                            </Link>
                          )}

                          <Badge
                            badgeContent={claim?.unread_by_customer}
                            sx={{
                              "& .MuiBadge-badge": {
                                backgroundColor: "#c55050",
                                color: "#fff",
                                fontSize: "12px",
                                minWidth: "20px",
                                height: "20px",
                                borderRadius: "10px",
                              },
                            }}
                            invisible={!claim?.unread_by_customer}
                          >
                            <Link
                              to={`view-claim?claim_id=${claim?.entity_id}`}
                              style={{
                                color: "#2b57a9",
                              }}
                            >
                              <button style={{ cursor: "pointer" }}>
                                {t("buyer_dashboard.claims.view")}
                              </button>
                            </Link>
                          </Badge>
                        </Box>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                ))}
              </Table>
            </Box>
            {totalItems > 9 && (
              <Box className="deskPagination">
                <Stack className="pagination-controls">
                  <Typography className="items-label">
                    {t("buyer_dashboard.myOrderList.Item_per_page")}:
                  </Typography>
                  <Select
                    value={pageSize}
                    onChange={handlePageSizeChange}
                    variant="outlined"
                    size="small"
                    className="items-select"
                  >
                    {array.map((size: any) => (
                      <MenuItem key={size.value} value={size.value}>
                        {size.label}
                      </MenuItem>
                    ))}
                  </Select>
                </Stack>

                <Pagination
                  count={totalPages}
                  page={currentPage}
                  onChange={handlePageChange}
                  shape="rounded"
                />
              </Box>
            )}
          </>
        ) : (
          <NoClaims />
        )}
      </Box>
    </>
  );
};

export default ClaimsProductList;
