import React, { useState, useContext, useEffect } from "react";
import Box from "@mui/material/Box";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import { Link, useLocation } from "react-router-dom";
import * as queries from "../../../../operations/queries";
import { useQuery } from "@apollo/client";
import { mainContext } from "../../MainComponent";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { CountryContext } from "../../MainComponent";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import Drawer from "@mui/material/Drawer";

import "./BuySidebar.scss";

import OrderIcon from "../../../../assets/images/order-icon.svg";
import WishlistIcon from "../../../../assets/images/wishlist-icon.svg";
import ReturnIcon from "../../../../assets/images/returns-icon.svg";
import PaymentIcon from "../../../../assets/images/payment-icon.svg";
import ClaimsIcon from "../../../../assets/images/claims-icon.svg";
// import JebelzCreditIcon from "../../../../assets/images/jebelz-credit-icon.svg";
import ProfileIcon from "../../../../assets/images/profile-icon.svg";
import { ListItemIcon } from "@mui/material";

type Route = {
  path: string;
  text: string;
  img: any;
};

function BuySidebar(props: any) {
  const [email, setEmail] = useState("");
  const [firstname, setFirstname] = useState("");
  const [lastname, setLastname] = useState("");
  const { t } = useTranslation();
  const location = useLocation();
  const [mobileOpen, setMobileOpen] = useState(false);
  const loggedInToken = localStorage.getItem("loggedInCust");
  const { setCartCount, set_quest_cart_id, isUpdate } = useContext(mainContext);
  const { store } = useContext(CountryContext);
  const navigate = useNavigate();
  const { window } = props;
  const drawerWidth = 300;
  const container =
    window !== undefined ? () => window().document.body : undefined;

  const { data: custDetailsDataRes, refetch: refectUserData } = useQuery(
    queries.customerQueries.GET_CUSTOMER_INFO,
    {
      skip: !loggedInToken,
      context: {
        headers: {
          Authorization: loggedInToken
            ? `Bearer ${JSON.parse(loggedInToken)}`
            : "",
          Connection: "keep-alive",
        },
        // pollInterval: 5000,
      },
    }
  );

  useEffect(() => {
    setEmail(custDetailsDataRes?.customer?.email || "");
    setFirstname(custDetailsDataRes?.customer?.firstname || "");
    setLastname(custDetailsDataRes?.customer?.lastname || "");
  }, [refectUserData, isUpdate, custDetailsDataRes]);

  useEffect(() => {
    refectUserData();
    //eslint-disable-next-line
  }, [custDetailsDataRes, refectUserData, isUpdate , store]);

  const routes: Route[] = [
    {
      path: "my-order-list",
      text: "layout.buysidebar.my_order",
      img: OrderIcon,
    },
    { path: "my-returns", text: "layout.buysidebar.return", img: ReturnIcon },
    {
      path: "claims",
      text: "layout.buysidebar.claims",
      img: ClaimsIcon,
    },
    {
      path: "payment",
      text: "layout.buysidebar.payment",
      img: PaymentIcon,
    },
    // {
    //   path: "credit",
    //   text: "layout.buysidebar.jebelez_credit",
    //   img: JebelzCreditIcon,
    // },
    {
      path: "my-wishlist",
      text: "layout.buysidebar.my_wishlist",
      img: WishlistIcon,
    },
    { path: "", text: "layout.buysidebar.my_account", img: ProfileIcon },
  ];

  const [selectedItem, setSelectedItem] = useState(() => {
    const currentPath = location.pathname;
    const matchingRoute = routes.find((route) =>
      currentPath.includes(route.path)
    );
    return matchingRoute ? matchingRoute.path : routes[0].path;
  });

  const handleDrawerToggle = () => {
    setMobileOpen((prevState) => !prevState);
  };

  useEffect(() => {
    const currentPath = location.pathname;
    const matchingRoute = routes.find((route) =>
      currentPath.includes(route.path)
    );
    setSelectedItem(matchingRoute ? matchingRoute.path : routes[0].path);
    //eslint-disable-next-line
  }, [location.pathname]);

  const handleItemClick = (path: React.SetStateAction<string>) => {
    setSelectedItem(path);
  };

  const handleLogout = () => {
    localStorage.removeItem("loggedInCust");
    localStorage.removeItem("LoggedInUser");
    localStorage.removeItem("wishlistId");
    localStorage.removeItem("quest_cart_id");
    sessionStorage.removeItem("storeAndCart");
    navigate(`/${store}`);
    set_quest_cart_id("");
    setCartCount(0);
    sessionStorage.removeItem("loggedInCust");
  };

  const drawer = (
    <Box className="directionrtl">
      <Box className="Menu-welcome-box">
        <h4>{t("layout.buysidebar.welcome")}!</h4>
        <p>
          {firstname} {lastname}
        </p>
        <p>{email}</p>
      </Box>

      {routes ? (
        <Box
          className="Menu-main-list"
          sx={{ width: "100%", maxWidth: "100%" }}
        >
          {/* Mobile Menu */}
          <>
            <List component="nav" aria-label="main mailbox folders">
              {routes?.map((route, index) => (
                <ListItemButton
                  key={index}
                  component={Link}
                  to={route.path}
                  selected={selectedItem === route?.path}
                  onClick={() => handleItemClick(route?.path)}
                  sx={{
                    backgroundColor:
                      selectedItem === route?.path ? "#e0e0e0" : "inherit",
                    "&:hover": {
                      backgroundColor: "#f0f0f0",
                    },
                    textAlign:
                      store === "ae_ar" || store === "ar" || store === "sa_ar"
                        ? "right"
                        : "left",
                  }}
                >
                  <ListItemIcon
                    sx={{
                      minWidth: 0,
                      marginRight:
                        store === "ae_ar" || store === "ar" || store === "sa_ar"
                          ? "0"
                          : "8px",
                      marginLeft:
                        store === "ae_ar" || store === "ar" || store === "sa_ar"
                          ? "8px"
                          : "0",
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <img
                      src={route?.img}
                      alt={route?.text}
                      style={{ width: 24, height: 24 }}
                    />
                  </ListItemIcon>
                  <ListItemText
                    primary={t(route?.text)}
                    sx={{
                      textAlign:
                        store === "ae_ar" || store === "ar" || store === "sa_ar"
                          ? "right"
                          : "left",
                    }}
                    className={selectedItem === route?.path ? "selected" : ""}
                  />
                </ListItemButton>
              ))}
            </List>

            <hr />

            <List component="nav" aria-label="main mailbox folders">
              <ListItemButton onClick={() => handleLogout()}>
                {/* <img src='' alt='' width="0px"/> */}
                <ListItemText>{t("layout.buysidebar.sign_out")}</ListItemText>
              </ListItemButton>
            </List>
          </>
        </Box>
      ) : (
        ""
      )}
    </Box>
  );
  return (
    <>
      <IconButton
        color="inherit"
        aria-label="open drawer"
        edge="start"
        onClick={handleDrawerToggle}
        sx={{ mr: 2, display: { md: "none", xs: "block" }, ml: "8px" }}
      >
        <MenuIcon />
      </IconButton>
      <Box
        component="nav"
        sx={{ width: { sm: "100%" }, flexShrink: { sm: 0 } }}
        aria-label="mailbox folders"
      >
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true,
          }}
          anchor={
            store === "ae_ar" || store === "ar" || store === "sa_ar"
              ? "right"
              : "left"
          }
          sx={{
            display: { xs: "block", md: "none" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
            },
          }}
        >
          {drawer}
        </Drawer>
        <Box
          sx={{
            display: { xs: "none", md: "block" },
          }}
          className="directionrtl"
        >
          <Box className="Menu-welcome-box">
            <h4>{t("layout.buysidebar.welcome")}!</h4>
            <p>
              {firstname} {lastname}
            </p>
            <p>{email}</p>
          </Box>

          <Box
            className="Menu-main-list"
            sx={{ width: "100%", maxWidth: "100%" }}
          >
            <List component="nav" aria-label="main mailbox folders">
              {/* Web Menu */}
              {routes.map((route, index) => (
                <>
                  <ListItemButton
                    key={index}
                    component={Link}
                    to={route.path}
                    selected={selectedItem === route.path}
                    onClick={() => handleItemClick(route.path)}
                    sx={{
                      backgroundColor:
                        selectedItem === route.path ? "#e0e0e0" : "inherit",
                      "&:hover": {
                        backgroundColor: "#f0f0f0",
                      },
                    }}
                  >
                    <img src={route.img} alt={route.text} />
                    {/* <ListItemText>Order</ListItemText> */}
                    <ListItemText
                      primary={t(route?.text)}
                      className={selectedItem === route?.path ? "selected" : ""}
                    />
                  </ListItemButton>
                </>
              ))}
            </List>
            <hr />
            <List component="nav" aria-label="main mailbox folders">
              <ListItemButton onClick={() => handleLogout()}>
                <ListItemText>{t("layout.buysidebar.sign_out")}</ListItemText>
              </ListItemButton>
            </List>
          </Box>
        </Box>
      </Box>
    </>
  );
}
export default BuySidebar;
