import Grid from "@mui/material/Grid";
import Container from "@mui/material/Container";
import { Route, Routes } from "react-router-dom";
import "./buyer-dashboard.scss";
import TrakingDetails from "./Orders/TrackingDetails/TrakingDetails";
import OrderSummary from "./Orders/OrderSummary/OrderSummary";
import Profile from "./Profile/Profile";
import BuySidebar from "./BuySidebar/BuySidebar";
import ProfileAddress from "./Profile/ProfileAddress/ProfileAddress";
import { CountryContext } from "../MainComponent";
import { useContext } from "react";
import PaymentAccount from "./payment/addCard/PaymentAccount";
import MyWishlist from "./Wishlist/MyWishlist";
import Wishlist from "./Wishlist/emptyWishlist/Wishlist";
import PaymentHomePage from "./payment/PaymentHomePage";
import MyOrderList from "./Orders/MyOrderList";
import ReturnItems from "./return/ReturnItems";
import ReturnCompleted from "./return/returnCompleted/ReturnCompletedd";
import ReturnOrder from "./Orders/returnOrder/ReturnOrder";
import ClaimsProductForm from "./Claims/ClaimsProductForm/ClaimsProductForm";
import ClaimsProductList from "./Claims/ClaimsProductList";
// import CreditHomePage from "./Credit/CreditHomePage";
import ClaimsProductDetails from "./Claims/ClaimsProductDetails/ClaimsProductDetails";

function BuyDashboard() {
  const { store } = useContext(CountryContext);
  return (
    <Container maxWidth={false} sx={{ p: "0px !important" }}>
      <div className="dashboard-wrapper directionrtl">
        <Grid container>
          <Grid item md={3} xs={12} sx={{pl:
                store === "ae_ar" || store === "ar" || store === "sa_ar"
                  ? { sm: "20px", xs: "20px",lg:'0px',md:'0px' }
                  : "0px"}}>
            <div className="sidebar-wrapper">
              <BuySidebar />
            </div>
          </Grid>
          <Grid
            item
            md={9}
            xs={12}
            sx={{
                  p:{xs: "20px 20px",md:'30px 30px' }
            }}
          >
            <div className="dash-content-wrap">
              {/* <MyAccountPage /> */}
              <Routes>
                <Route path="/" element={<Profile />} />
                <Route path="/add-new-address" element={<ProfileAddress />} />
                <Route path="/my-order-list" element={<MyOrderList />} />
                <Route
                  path="/my-order-list/order/:orderNumber"
                  element={<OrderSummary />}
                />
                <Route
                  path="/my-order-list/tracking/:orderNumber"
                  element={<TrakingDetails />}
                />
                <Route
                  path="/claims/:orderNumber"
                  element={<ClaimsProductForm />}
                />
                <Route path="/my-order-list/return-order/:orderNumber" element={<ReturnOrder />} />
                <Route path="/my-wishlist" element={<MyWishlist />} />
                <Route path="/my-wishlist/create-new" element={<Wishlist />} />
                <Route path="/my-returns" element={<ReturnItems />} />
                <Route path="/my-returns/return-completed" element={<ReturnCompleted />} />
                <Route path="/payment" element={<PaymentHomePage />} />
                <Route path="/payment/new" element={<PaymentAccount />} />
                <Route path="/claims" element={<ClaimsProductList />} />
                <Route path="/claims/new" element={<ClaimsProductForm />} />
                <Route path="/claims/view-claim" element={<ClaimsProductDetails />} />
                {/* <Route path="/credit" element={<CreditHomePage />} /> */}
               
              </Routes>
            </div>
          </Grid>
        </Grid>
      </div>
    </Container>
  );
}
export default BuyDashboard;
