import { gql } from "@apollo/client";

export const ADD_CLAIM = gql`
  mutation addClaim(
    $order_id: String!
    $issue_type: String!
    $description: String!
    $file: [ImageInput]!
  ) {
    addClaim(
      order_id: $order_id
      issue_type: $issue_type
      description: $description
      file: $file
    ) {
      entity_id
      customer_id
      email
      name
      order_id
      item_id
      product_name
      price
      qty
      total_price
      sku
      issue_type
      description
      seller_id
      seller_name
      created_at
      updated_at
      claim_status
      image_gallery
    }
  }
`;

export const UPDATE_CLAIM = gql`
  mutation updateClaim(
    $entity_id: Int!
    $issue_type: String!
    $description: String!
    $file: [ImageInput]!
  ) {
    updateClaim(
      entity_id: $entity_id
      issue_type: $issue_type
      description: $description
      file: $file
    ) {
      entity_id
      customer_id
      email
      name
      order_id
      item_id
      product_name
      price
      qty
      total_price
      sku
      issue_type
      description
      seller_id
      seller_name
      created_at
      updated_at
      claim_status
      image_gallery
    }
  }
`;

export const DELETE_CLAIM = gql`
  mutation deleteClaim($entity_id: Int!) {
    deleteClaim(entity_id: $entity_id) {
      entity_id
    }
  }
`;

export const VIEW_SELLER_CLAIM = gql`
  mutation viewSellerSideClaim($claimid: Int!) {
    viewSellerSideClaim(claimid: $claimid) {
      claimid
      customer_id
      customer_email
      order_id
      item_id
      date
      product_name
      qty
      price
      totalprice
      claim_status
      issue_type
      description
      image
      product_image_url
    }
  }
`;

export const 
ADD_CHAT_COMMUNICATION = gql`
  mutation addChatCommunication(
    $claimid: String!
    $communication: String!
    $timestamp: String!
    $sender: String!
    $is_read_by_seller: String!
    $is_read_by_customer: String!
  ) {
    addChatCommunication(
      claimid: $claimid
      communication: $communication
      timestamp: $timestamp
      sender: $sender
      is_read_by_seller: $is_read_by_seller
      is_read_by_customer: $is_read_by_customer
    ) {
      success
      message
    }
  }
`;

export const CUSTOMER_RESOLUTION_MUTATION = gql`
  mutation customerResolution($entity_id: Int!, $resolution: String!) {
    customerResolution(entity_id: $entity_id, resolution: $resolution) {
      success
      message
      entity_id
      seller_refund_type
      resolution
      claim_status
      updated_at
    }
  }
`;

export const MARK_MESSAGES_READ = gql`
  mutation MarkMessagesRead($claimid: String!, $sender: String!) {
    markMessagesRead(claimid: $claimid, sender: $sender) {
      success
      message
      claimid
    }
  }
`;
